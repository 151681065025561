<template>
  <v-card @click="goToProfile" class="rounded-lg">
    <div class="pa-5 d-flex flex-row justify-start h-100" v-if="volunteer">
      <volunteer-avatar
        :img="volunteer.midThumbnail ? volunteer.midThumbnail : volunteer.image"
        :name="volunteer.first_name + volunteer.last_name"
        :size="60"
      />
      <div class="d-flex flex-column">
        <span
          class="primary-color font-12 app-bold-font text-left text-ellipsis break-word line-height-15 force-height-30 overflow-hidden align-center py-3 ml-4"
        >
          {{ volunteer.first_name + " " + volunteer.last_name }}
        </span>
        <span
          class="gray-font-color font-12 text-left text-ellipsis break-word line-height-15 force-height-30 overflow-hidden align-center py-3 ml-4"
        >
          {{ volunteer.fanned.length }}
          {{ volunteer.fanned.length > 1 ? $t("followers") : $t("follower") }}
        </span>
      </div>
    </div>
    <v-skeleton-loader
      type="list-item-avatar-two-line"
      style="padding-top: 14px; padding-bottom: 14px;"
      v-else
    />
    <v-btn
      block
      style="max-height: 40px; height: 40px;"
      :color="getFanned ? '#A1CC33' : '#472583'"
      dark
      class="rounded-0 rounded-b-lg"
      :loading="loading"
    >
      {{
        getFanned
          ? "Fan"
          : volunteer &&
            volunteer.first_name == "Private" &&
            volunteer.last_name == "User"
          ? "Locked"
          : "Not Following you"
      }}
    </v-btn>
  </v-card>
</template>
<script>
import { mapActions, mapState } from "vuex";
import VolunteerAvatar from "../../volunteer/VolunteerAvatar.vue";
export default {
  components: { VolunteerAvatar },
  props: {
    _id: String
  },
  data() {
    return {
      loading: false,
      volunteer: null
    };
  },
  computed: {
    ...mapState("auth", {
      profile: "profile",
      type: "type"
    }),
    getFanned() {
      if (!this.profile.fanned) return false;
      if (!this.volunteer) return false;
      if (this.profile.fanned.includes(this.volunteer._id)) return true;
      return false;
    }
  },
  methods: {
    ...mapActions("volunteer", {
      getVolunteer: "getVolunteer"
    }),
    goToProfile() {
      if (this.volunteer._id) {
        this.$router.push({
          name: "volunteer-profile-details",
          params: { id: this.volunteer._id }
        });
      }
    }
  },
  mounted() {
    this.loading = true;
    this.getVolunteer({ _id: this._id })
      .then(res => {
        this.loading = false;
        this.volunteer = res;
      })
      .catch(error => {
        this.loading = false;
        console.log(error.response.data.message);
        if (
          error.response &&
          error.response.data.message == "Permission denied"
        ) {
          this.volunteer = {
            first_name: "Private",
            last_name: "User",
            fanned: []
          };
        }
      });
  }
};
</script>
<style></style>
